import React, { forwardRef } from 'react';
import DropdownFilter from './DropdownFilter';

const Filters = forwardRef(
  (
    {
      openLocation,
      openTeam,
      labelLocation,
      labelTeam,
      locationItems,
      teamItems,
      selectedLocation,
      selectedTeam,
      handleCheckLocation,
      handleCheckTeam,
      handleOpenLocation,
      handleOpenTeam,
    },
    ref
  ) => (
    <div ref={ref} className="filter-wrapper">
      <DropdownFilter
        open={openLocation}
        label="Filter by Location"
        buttonLabel={labelLocation}
        items={locationItems}
        checkedItems={selectedLocation}
        onCheck={handleCheckLocation}
        onOpen={handleOpenLocation}
      />
      <DropdownFilter
        open={openTeam}
        label="Filter by Team"
        buttonLabel={labelTeam}
        items={teamItems}
        checkedItems={selectedTeam}
        onCheck={handleCheckTeam}
        onOpen={handleOpenTeam}
      />
    </div>
  )
);

export default Filters;
